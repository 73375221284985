/* shippori-mincho-b1-regular - latin */
@font-face {
  font-family: 'Shippori Mincho B1';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/shippori-mincho-b1-v10-latin-regular.woff2')
    format('woff2');
}

/* shippori-mincho-b1-500 - latin */
@font-face {
  font-family: 'Shippori Mincho B1';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/shippori-mincho-b1-v10-latin-500.woff2') format('woff2');
}

/* shippori-mincho-b1-600 - latin */
@font-face {
  font-family: 'Shippori Mincho B1';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/shippori-mincho-b1-v10-latin-600.woff2') format('woff2');
}

/* shippori-mincho-b1-700 - latin */
@font-face {
  font-family: 'Shippori Mincho B1';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/shippori-mincho-b1-v10-latin-700.woff2') format('woff2');
}

/* shippori-mincho-b1-800 - latin */
@font-face {
  font-family: 'Shippori Mincho B1';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/shippori-mincho-b1-v10-latin-800.woff2') format('woff2');
}

/* nunito-200 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/nunito-v16-latin-200.woff2') format('woff2');
}

/* nunito-200italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  src: url('./assets/nunito-v16-latin-200italic.woff2') format('woff2');
}

/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/nunito-v16-latin-300.woff2') format('woff2');
}

/* nunito-300italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/nunito-v16-latin-300italic.woff2') format('woff2');
}

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/nunito-v16-latin-regular.woff2') format('woff2');
}

/* nunito-italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/nunito-v16-latin-italic.woff2') format('woff2');
}

/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/nunito-v16-latin-600.woff2') format('woff2');
}

/* nunito-600italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/nunito-v16-latin-600italic.woff2') format('woff2');
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/nunito-v16-latin-700.woff2') format('woff2');
}

/* nunito-700italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/nunito-v16-latin-700italic.woff2') format('woff2');
}

/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/nunito-v16-latin-800.woff2') format('woff2');
}

/* nunito-800italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/nunito-v16-latin-800italic.woff2') format('woff2');
}

/* nunito-900 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/nunito-v16-latin-900.woff2') format('woff2');
}

/* nunito-900italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('./assets/nunito-v16-latin-900italic.woff2') format('woff2');
}
